import React, { useEffect, useMemo, useState } from 'react';
import styles from './index.module.scss';
import {
  Button,
  Cascader,
  DatePicker,
  Modal,
  Search,
  Select,
  Table,
  Toast,
  Tooltip,
} from 'shopee-ui-react';
import dayjs from 'dayjs';
import { AttendanceReportListItem, GetAttendanceReportParams } from '@api/type';
import {
  exportAttendanceReport,
  getAttendanceReportConfig,
  getAttendanceReportList,
} from '@api/index';
import MultipleSelect from '@components/MultipleSelect';

const getDefaultParams = (): GetAttendanceReportParams => {
  return {
    staffKeyword: '',
    legalIds: [],
    officeIds: [],
    teamIds: [],
    employmentTypes: [],
    startDate: dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
    endDate: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
    pageNum: 1,
    pageSize: 10,
  };
};

const handleMutipleOption = (value: number | string, label: string) => {
  return {
    value,
    label,
  };
};

const handleCascaderOptions = (arr: any) => {
  return arr.map(function (item: any) {
    const newItem = {
      value: item.id,
      label: item.name,
      children: handleCascaderOptions(item.subList),
    };
    return newItem;
  });
};

const AttendanceReport = () => {
  const [listParams, setListParams] = useState(getDefaultParams());

  const [optionsMap, setOptionsMap] = useState<any>({
    legalEntityOpitons: [],
    officeOptions: [],
    employmentTypeOptions: [],
    teamOptions: [],
  });

  const [tableData, setTableData] = useState<AttendanceReportListItem[]>([]);
  const [pageInfo, setPageInfo] = useState({
    total: 0,
    current: 1,
    pageSize: 10,
  });
  const [tableLoading, setTableLoading] = useState(false);
  const [tableSelectRowKeys, setTableSelectRowKeys] = useState<any[]>([]);
  const [isExporting, setIsExporting] = useState(false);

  const renderText = (text: string) => (
    <Tooltip
      content={text || '-'}
      placement="topLeft">
      <span className={styles.ellipsis}>{text || '-'}</span>
    </Tooltip>
  );

  const columns = useMemo(() => {
    return [
      {
        title: 'Staff ID',
        dataIndex: 'staffId',
        ellipsis: true,
        width: 112,
        fixed: true,
        render: renderText,
      },
      {
        title: 'Name',
        dataIndex: 'staffName',
        ellipsis: true,
        width: 112,
        render: renderText,
      },
      {
        title: 'Email',
        dataIndex: 'email',
        ellipsis: true,
        width: 232,
        render: renderText,
      },
      {
        title: 'Office',
        dataIndex: 'office',
        ellipsis: true,
        width: 232,
        render: renderText,
      },
      {
        title: 'Team',
        dataIndex: 'teamPath',
        ellipsis: true,
        width: 248,
        render: renderText,
      },
      {
        title: 'Reporting Manager',
        dataIndex: 'reportingManagerEmail',
        ellipsis: true,
        width: 232,
        render: renderText,
      },
      {
        title: 'Reporting Line',
        dataIndex: 'reportingLine',
        ellipsis: true,
        width: 248,
        render: renderText,
      },
      {
        title: 'Date',
        dataIndex: 'date',
        width: 160,
        render: renderText,
      },
      {
        title: 'Day',
        dataIndex: 'day',
        ellipsis: true,
        width: 104,
        render: renderText,
      },
      {
        title: 'Is Public Holiday',
        dataIndex: 'isPublicHoliday',
        ellipsis: true,
        width: 160,
        render: renderText,
      },
      {
        title: 'Is On Leave',
        dataIndex: 'isOnLeave',
        ellipsis: true,
        width: 160,
        render: renderText,
      },
      {
        title: 'Leave Type',
        dataIndex: 'leaveType',
        ellipsis: true,
        width: 160,
        render: renderText,
      },
      {
        title: 'Is On Business Travel',
        dataIndex: 'isOnBusinessTravel',
        ellipsis: true,
        width: 160,
        render: renderText,
      },
      {
        title: 'Is Exempted',
        dataIndex: 'isExempted',
        ellipsis: true,
        width: 160,
        render: renderText,
      },
      {
        title: 'Exemption Reason',
        dataIndex: 'exemptionReason',
        ellipsis: true,
        width: 232,
        render: renderText,
      },
      {
        title: 'System Tap In',
        dataIndex: 'systemTapIn',
        ellipsis: true,
        width: 160,
        render: renderText,
      },
      {
        title: 'System Tap Out',
        dataIndex: 'systemTapOut',
        ellipsis: true,
        width: 160,
        render: renderText,
      },
      {
        title: 'Remarks(web)',
        dataIndex: 'webRemark',
        ellipsis: true,
        width: 248,
        render: renderText,
      },
      {
        title: 'Notes for Correction(app)',
        dataIndex: 'appCorrectRemark',
        ellipsis: true,
        width: 232,
        render: renderText,
      },
      {
        title: 'Tap In Correction(app)',
        dataIndex: 'correctTapIn',
        ellipsis: true,
        width: 232,
        render: renderText,
      },
      {
        title: 'Tap Out Correction(app)',
        dataIndex: 'correctTapOut',
        ellipsis: true,
        width: 232,
        render: renderText,
      },
      {
        title: 'Duration',
        dataIndex: 'duration',
        ellipsis: true,
        width: 98,
        render: renderText,
      },
    ];
  }, []);

  const getAttendanceReport = async (options: any) => {
    setTableLoading(true);
    const { code, data, message } = await getAttendanceReportList(options);
    if (code !== 0) {
      Toast.error(message);
      setTableLoading(false);
      return;
    }
    setTableData(data.attendanceList);
    setPageInfo({
      current: data.pageInfo.pageNum,
      pageSize: data.pageInfo.pageSize,
      total: data.pageInfo.total,
    });
    setTableLoading(false);
  };

  const getAttendanceSelectInfo = async () => {
    const { code, data } = await getAttendanceReportConfig();
    if (code !== 0) {
      Toast.error('Get Select Info Error');
      return;
    }
    setOptionsMap({
      legalEntityOpitons: data.openedLegalList.map(item =>
        handleMutipleOption(item.id, item.name)
      ),
      officeOptions: data.officeList.map(item =>
        handleMutipleOption(item.id, item.name)
      ),
      employmentTypeOptions: data.employmentTypeList.map(item =>
        handleMutipleOption(item.code, item.name)
      ),
      teamOptions: handleCascaderOptions(data.orgList),
    });
  };

  const handleSearchKeyChange = (value: any) => {
    setListParams({
      ...listParams,
      staffKeyword: value,
    });
  };

  const handleLegalIdChange = (value: number[]) => {
    setListParams({
      ...listParams,
      legalIds: value,
    });
  };

  const handleOfficeIdChange = (value: number[]) => {
    setListParams({
      ...listParams,
      officeIds: value,
    });
  };

  const handleEmploymentTypeChange = (value: string[]) => {
    setListParams({
      ...listParams,
      employmentTypes: value,
    });
  };

  const handleTeamIdChange = (value: any[]) => {
    setListParams({
      ...listParams,
      teamIds: value,
    });
  };

  const handleDateRangeChange = (range: any) => {
    setListParams({
      ...listParams,
      startDate: dayjs(range.startDate).format('YYYY-MM-DD'),
      endDate: dayjs(range.endDate).format('YYYY-MM-DD'),
    });
  };

  const handlePageChange = (current: number) => {
    const options = {
      ...listParams,
      pageNum: current,
    };
    setListParams(options);
    setPageInfo({
      ...pageInfo,
      current,
    });
    getAttendanceReport(options);
  };

  const handlePageSizeChange = (pageSize: number) => {
    const options = {
      ...listParams,
      pageNum: 1,
      pageSize,
    };
    setListParams(options);
    setPageInfo({
      ...pageInfo,
      pageSize,
      current: 1,
    });
    getAttendanceReport(options);
  };

  const handleSubmit = () => {
    const options = {
      ...listParams,
      pageSize: pageInfo.pageSize,
      pageNum: 1,
    };
    setListParams(options);
    setPageInfo({
      ...pageInfo,
      pageSize: pageInfo.pageSize,
      current: 1,
    });
    getAttendanceReport(options);
  };

  const handleExport = async () => {
    try {
      setIsExporting(true);
      const res = await exportAttendanceReport({
        ...listParams,
        attendanceIds: tableSelectRowKeys,
      });
      const data = res.data;
      const headers = res.headers;
      const regex = /filename="([^"]+)"/;
      const match = regex.exec(headers['content-disposition']);
      let filename = 'Attendance Report.csv';
      if (match && match[1]) {
        filename = match[1];
      }

      const blob = new Blob([data], { type: 'application/octet-stream' });
      // 创建下载链接
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = filename;

      // 触发下载
      downloadLink.click();

      setIsExporting(false);
    } catch (err) {
      setIsExporting(false);
      Modal.alarm({
        title: 'Notification',
        content: 'Export Attendance Report Error',
      });
    }
  };

  const handleSelectedRowsChange = (
    selectedRowKeys: any,
    selectedRows: any
  ) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      'selectedRows: ',
      selectedRows
    );
    const currentPageSelectRows = `${selectedRowKeys}`
      .split(',')
      .map(item => Number(item));
    const currentPageRow = tableData.map(item => item.attendanceId);
    const set = new Set([...currentPageSelectRows, ...tableSelectRowKeys]);
    currentPageRow.forEach(item => {
      if (set.has(item) && !currentPageSelectRows.includes(item)) {
        set.delete(item);
      }
    });
    const filterArr = Array.from(set);
    console.log('filterArr', filterArr);
    setTableSelectRowKeys(filterArr);
  };

  const handleReset = () => {
    const options = getDefaultParams();
    getAttendanceReport(options);
    setListParams(options);
  };

  useEffect(() => {
    getAttendanceSelectInfo();
    getAttendanceReport(listParams);
  }, []);

  useEffect(() => {
    performance.mark('FCP');
  }, []);

  return (
    <div className={styles.report_wrapper}>
      <div className={styles.report_title}>Attendance Report</div>
      <div className={styles.filter_wrapper}>
        <div className={styles.filter_item}>
          <div className={styles.filter_label}>Staff ID/Name/Email</div>
          <Search
            value={listParams.staffKeyword}
            onChange={handleSearchKeyChange}
            placeholder="Search"
          />
        </div>

        <div className={styles.filter_item}>
          <div className={styles.filter_label}>Legal Entities</div>
          <MultipleSelect
            options={optionsMap.legalEntityOpitons}
            value={listParams.legalIds}
            onChange={handleLegalIdChange}
          />
        </div>

        <div className={styles.filter_item}>
          <div className={styles.filter_label}>Office</div>
          <MultipleSelect
            options={optionsMap.officeOptions}
            value={listParams.officeIds}
            onChange={handleOfficeIdChange}
          />
        </div>

        <div className={styles.filter_item}>
          <div className={styles.filter_label}>Team</div>
          <Cascader
            value={listParams.teamIds}
            maxLine={1}
            clearable
            multiple
            onChange={handleTeamIdChange}
            options={optionsMap.teamOptions}
          />
        </div>

        <div className={styles.filter_item}>
          <div className={styles.filter_label}>Employment Type</div>
          <MultipleSelect
            options={optionsMap.employmentTypeOptions}
            value={listParams.employmentTypes}
            onChange={handleEmploymentTypeChange}
          />
        </div>

        <div className={styles.filter_item}>
          <div className={styles.filter_label}>Date</div>
          <DatePicker
            onChange={handleDateRangeChange}
            type="daterange"
            value={{
              startDate: dayjs(listParams.startDate).toDate(),
              endDate: dayjs(listParams.endDate).toDate(),
            }}
            format={(value: any) => {
              return `${dayjs(value.startDate).format('DD/MM/YYYY')} - ${dayjs(
                value.endDate
              ).format('DD/MM/YYYY')}`;
            }}
            max={dayjs().subtract(1, 'day').toDate()}
          />
        </div>

        <div className={styles.filter_item}>
          <Button
            className={styles.submit}
            type="primary"
            onClick={handleSubmit}>
            Submit
          </Button>
          <Button
            className={styles.reset}
            onClick={handleReset}>
            Reset
          </Button>
        </div>
      </div>

      <div className={styles.table_wrapper}>
        <div className={styles.table_header}>
          <div className={styles.table_title}>{pageInfo.total} Records</div>
          <Button
            disabled={!pageInfo.total || isExporting}
            onClick={handleExport}
            loading={isExporting}>
            Export
          </Button>
        </div>
        <Table
          rowKey="attendanceId"
          rowSelection={{
            selectedRowKeys: tableSelectRowKeys,
            onChange: handleSelectedRowsChange,
          }}
          loading={tableLoading}
          dataSource={tableData}
          columns={columns}
          pagination={{
            onChange: handlePageChange,
            onSizeChange: handlePageSizeChange,
            ...pageInfo,
            hideOnSinglePage: false,
            layout: 'pages,sizes,jumper',
          }}
          scroll={{ x: 1300 }}
        />
      </div>
    </div>
  );
};

export default AttendanceReport;
